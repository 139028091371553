<template>
  <b-overlay
    :show="busy"
    rounded="sm"
  >
    <b-overlay
      :show="show"
      no-wrap
    />
      <content-with-sidebar
        class="cws-container cws-sidebar-right blog-wrapper"
      >
      <!-- content -->
      <div class="blog-detail-wrapper">
        <b-row>
          <!-- blogs -->
          <b-col cols="12">
            <b-card
              :img-src="itemDetail.image"
              img-top
              :img-alt="itemDetail.nama"
              :title="itemDetail.nama"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="itemDetail.avatar"
                    :to="{ name: 'pages-profile-detail', params: {id: itemDetail.created_by }}"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link :to="{ name: 'pages-profile-detail', params: {id: itemDetail.created_by }}" class="text-body" v-if="itemDetail.created_by_user">{{ itemDetail.created_by_user.name }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ itemDetail.datePost }}</small>
                </b-media-body>
              </b-media>
              <div class="my-1 py-25">
                <b-link               v-for="tag in itemDetail.tags"
                  :key="tag"
                >
                  <b-badge
                    pill
                    class="mr-75"
                    :variant="tagsColor(tag)"
                  >
                    {{ tag }}
                  </b-badge>
                </b-link>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div class="my-1 py-25">
                <!-- <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ itemDetail.lokasi }}</span>
                </div> -->
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ itemDetail.tanggal }}</span>
                </div>
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="ArrowUpCircleIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Target Proyek: {{ itemDetail.target }}</span>
                </div>
                <!-- <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="ShoppingBagIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Rp. 1.000.000.000</span>
                </div> -->
                <!-- <div class="d-flex align-items-center text-body"
                  v-if="projekId == 1"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Aktif</span>
                </div>
                <div class="d-flex align-items-center text-body"
                  v-if="projekId == 2"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Aktif</span>
                </div>
                <div class="d-flex align-items-center text-body"
                  v-if="projekId == 3"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Tidak Aktif</span>
                </div> -->
              </div>

              <!-- avatar group -->
              <h6 class="font-weight-bolder">
                Struktur Anggota
              </h6>
              <b-avatar-group
                class="mt-1 pt-50"
                size="33"
              >
                <b-avatar
                  v-for="avatar in itemDetail.user_proyek_all"
                  :key="avatar.avatar"
                  v-b-tooltip.hover.bottom="avatar.name"
                  :src="avatar.avatar"
                  :to="{ name: 'pages-profile-detail', params: {id: avatar.user_id}}"
                  class="pull-up"
                />
                <h6 class="align-self-center ml-1 mb-0">
                  {{ itemDetail.total }} Anggota
                </h6>
              </b-avatar-group>
              <h6 class="font-weight-bolder mt-1">
                Keterangan
              </h6>
              <div
                class="blog-content mt-1"
                v-html="itemDetail.deskripsi"
              />
              <h6 class="font-weight-bolder mt-1">
                Hasil
              </h6>
              <div
                class="blog-content mt-1"
                v-html="itemDetail.outcome"
              />
              <hr class="my-2">

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-1">
                    <b-button
                      v-if="statusTombol == 0 && $can('join', 'proyek')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="join"
                    >
                      Join
                    </b-button>

                    <b-button
                      v-if="statusTombol == 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="info"
                      class="mr-1"
                    >
                      Menunggu Persetujuan
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="mr-1"
                      v-if="statusTombol == 3"
                    >
                      Ditolak
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      v-if="statusTombol == 2"
                    >
                      Disetujui
                    </b-button>
                  </div>
                </div>

              </div>
            </b-card>
          </b-col>
        </b-row>
        <!--/ blogs -->
      </div>
      <!--/ content -->

      <!-- sidebar -->
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <!-- input search -->
        <!-- <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon
                icon="SearchIcon"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->
        <!--/ input search -->

        <!-- recent posts -->
        <div class="blog-recent-posts">
          <h6 class="section-label mb-75">
            Proyek Lainnya
          </h6>
          <b-media
            v-for="(recentpost,index) in thisSidebar.recentPosts"
            :key="recentpost.img"
            no-body
            :class="index? 'mt-2':''"
          >
            <b-media-aside class="mr-2">
              <b-link
                :to="{ name: 'pages-projek-detail', params: { id: recentpost.id } }"
              >
                <b-img
                  :src="recentpost.img"
                  :alt="recentpost.img.slice(6)"
                  width="100"
                  rounded
                  height="70"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  class="text-body-heading"
                  :to="{ name: 'pages-projek-detail', params: { id: recentpost.id } }"
                >
                  {{ recentpost.title }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ recentpost.createdTime }}
              </span>
            </b-media-body>
          </b-media>
        </div>
      </div>
    </content-with-sidebar>
    <template v-slot:overlay>
      <div
        v-if="processing"
        class="text-center p-4 bg-primary text-light rounded"
      >
        <b-icon
          icon="cloud-upload"
          font-scale="4"
        />
        <div class="mb-3">
          Processing...
        </div>
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div
        v-else
        ref="dialog"
        tabindex="-1"
        role="dialog"
        aria-modal="false"
        aria-labelledby="form-confirm-label"
        class="text-center p-3"
      >
        <p><strong id="form-confirm-label">Apakah Anda Yakin?</strong></p>
        <div class="d-flex">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-3"
            @click="onCancel"
          >
            Tidak
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            @click="onOK"
          >
            OK
          </b-button>
        </div>
      </div>
      <b-toast />
    </template>
  </b-overlay>
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BAvatarGroup, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton, VBTooltip, BOverlay, BToast,
} from 'bootstrap-vue'
import router from '@/router'
import {
  getProyek, insUserProyek,
} from '@/api/proyek'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    // BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BAvatarGroup,
    BOverlay,
    BToast,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    BImg,
    BBadge,
    // BCardText,
    // BDropdown,
    // BForm,
    // BDropdownItem,
    // BFormTextarea,
    // BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      itemDetail: [],
      thisSidebar: {},
      projekId: null,
      userId: null,
      statusTombol: 0,
      statusPrompt: null,
      busy: false,
      show: false,
      // avatars: [
      //   { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
      //   { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
      //   { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
      //   { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
      //   { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
      // ],
      comment: 44,
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      amres: localStorage.getItem('amres'),
    }
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userId = userData.id
    if (router.currentRoute.params.id) {
      this.projekId = router.currentRoute.params.id
      const response = await getProyek(this.$route.params.id)
      this.itemDetail = response.data.data
      if (this.itemDetail.status_approval_id !== null) {
        this.statusTombol = this.itemDetail.status_approval_id
      }
    }
  },
  created() {
    // this.$http.get('/blog/list/data/projekdetail').then(res => { this.itemDetail = res.data })
    // this.$http.get('/blog/list/data/projeksidebar').then(res => { thisSidebar = res.data })
  },
  methods: {
    join() {
      this.busy = true
      this.statusPrompt = 'join'
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      // if (this.statusPrompt === 'join') {
      if (this.amres === 'true') {
        this.busy = false

        const formData = {
          user_id: this.userId,
          proyek_id: this.$route.params.id,
          jenis_user_id: 1,
          status_approval_id: 1,
        }
        const response = await insUserProyek(formData)
        if (response.data.status.code === '00') {
          this.statusTombol = 1
          this.makeToast('success', 'Permintaan Anda menunggu persetujuan Admin!')
        } else {
          this.makeToast('danger', response.data.message)
        }
        this.show = false
        // } else {
        //   this.busy = false
        //   this.show = false
        //   this.showMsgBoxAmres()
        // }
      }
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: 'Proyek',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
    showMsgBoxAmres() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk('Mohon melengkapi Data Pengguna terlebih dahulu.', {
          title: 'Informasi Kelengkapan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Tutup',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
